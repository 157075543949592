<template>
    <div class="card-tail flex justify-end space-x-2 border-b">
        <div class="w-[70px]">
            <el-input v-model="filterNumber" placeholder="Ver" @input="filtersChanged" @keyup.native.enter="filtersChangedImmediately" />
        </div>
        <div class="w-[65px]">
            <el-input v-model="filterAccount" placeholder="Konto" @input="filtersChanged" @keyup.native.enter="filtersChangedImmediately" />
        </div>
        <div class="w-[100px]">
            <el-input v-model="filterDate" placeholder="Datum" @input="filtersChanged" @keyup.native.enter="filtersChangedImmediately" />
        </div>
        <div class="w-[100px]">
            <el-input v-model="filterRegistrationDate" placeholder="Reg datum" @input="filtersChanged" @keyup.native.enter="filtersChangedImmediately" />
        </div>
        <div class="flex-1">
            <el-input v-model="filterText" placeholder="Text" @input="filtersChanged" @keyup.native.enter="filtersChangedImmediately" />
        </div>
        <div class="w-[100px]">
            <InputCurrency
                v-model="filterAmount"
                placeholder="Belopp"
                custom-class="w-full text-xs py-[5px] px-2 text-right border focus:outline-none border-gray-200 focus:border-gray-500 rounded"
                @keyUp="filtersChanged"
                @keyup.native.enter="filtersChangedImmediately"
            />
        </div>
        <div>
            <el-button type="warning" @click="clearFilters" class="py-[6px] px-[9px]">
                <i class="fas fa-trash" />
            </el-button>
        </div>
    </div>
</template>
<script>
import queryString from "query-string";

export default {
    data() {
        return {
            filterNumber: "",
            filterAccount: "",
            filterDate: "",
            filterRegistrationDate: "",
            filterText: "",
            filterAmount: null,
            timer: null,
        };
    },

    components: {
        InputCurrency: () => import(/* webpackChunkName: "InputCurrency" */ "@/components/InputCurrency.vue"),
    },

    created() {
        this.assignInitValues();
    },

    methods: {
        assignInitValues() {
            const { filterNumber, filterAccount, filterDate, filterRegistrationDate, filterText, filterAmount } = queryString.parse(location.search);
            this.filterNumber = filterNumber || this.filterNumber;
            this.filterAccount = filterAccount || this.filterAccount;
            this.filterDate = filterDate || this.filterDate;
            this.filterRegistrationDate = filterRegistrationDate || this.filterRegistrationDate;
            this.filterText = filterText || this.filterText;
            this.filterAmount = filterAmount || this.filterAmount;
        },

        filtersChangedImmediately() {
            clearInterval(this.timer);
            this.updateFilters();
            this.$emit("change");
        },

        async filtersChanged() {
            this.updateFilters();
            this.emitChange();
        },

        async updateFilters() {
            const query = queryString.parse(location.search, { sort: false });
            query.filterNumber = this.filterNumber;
            query.filterAccount = this.filterAccount;
            query.filterDate = this.filterDate;
            query.filterRegistrationDate = this.filterRegistrationDate;
            query.filterText = this.filterText;
            query.filterAmount = this.filterAmount;
            await this.$router.replace({ query }).catch(() => {});
        },

        emitChange() {
            clearInterval(this.timer);
            this.timer = setTimeout(() => {
                this.$emit("change");
            }, 1000);
        },

        clearFilters() {
            this.reset();
            this.$emit("change");
        },

        reset() {
            this.filterNumber = "";
            this.filterAccount = "";
            this.filterDate = "";
            this.filterRegistrationDate = "";
            this.filterText = "";
            this.filterAmount = null;
            const query = queryString.parse(window.location.search, { sort: false });
            query.filterNumber = "";
            query.filterAccount = "";
            query.filterDate = "";
            query.filterRegistrationDate = "";
            query.filterText = "";
            query.filterAmount = "";
            this.$router.replace({ query }).catch(() => {});
            this.timer = null;
        },
    },
};
</script>
